import {FunctionComponent} from "react";
import {useUtilizationSummaryLogic} from "./UtilizationSummary.logic";
import {isDefined, isUndefined} from "is-type-util";
import {calculatePercentage} from "../../util/number-util";
import s from './UtilizationSummary.module.scss'

const shouldDisplayDays: boolean = false;

const minToReadableFormat = (totalMinutes?: number): string | undefined => {
  if (isUndefined(totalMinutes) || !totalMinutes) {
    // no value in making readable
    return undefined;
  }
  let days: number, hours: number;
  if (shouldDisplayDays){

    days = Math.floor((totalMinutes / 60) / 24);
    hours = Math.floor(totalMinutes / 60) % 24;
  } else {
    days = 0;
    hours = Math.floor(totalMinutes / 60);
  }
  const minutes = totalMinutes % 60;
  const outputChunks: string[] = [];
  if (days > 0) {
    outputChunks.push(`${days}d`);
  }
  if (hours > 0) {
    outputChunks.push(`${hours}h`);
  }
  if (minutes > 0) {
    outputChunks.push(`${minutes}m`);
  }
  return outputChunks.join(` `);
}

const UtilizationSummaryItem: FunctionComponent<{title: string, value?: number, total?: number}> = ({title, value, total}) => {

  if (!isDefined(value) || !value) {
    // nothing to display
    return null;
  }
  const percentage = calculatePercentage(value, total);
  return (
    <div className={s.item}>
      <div className={s.title}>{title}:</div>
      <div className={s.value}>{minToReadableFormat(value)}</div>
      <div className={s.percent}>{!!percentage ? ` /  ${percentage.toFixed(1)} %` : ` `}</div>

    </div>
  )
}




export const UtilizationSummary: FunctionComponent = ({}) => {
  const {shouldDisplay, summary} = useUtilizationSummaryLogic();
  if (!shouldDisplay) {
    // shouldn't be displayed on screen
    return null;
  }
  return (
    <div className={s.container}>
      <div className={s.sectionTitle}>UtilizationSummary</div>
      <div className={s.items}>
        <UtilizationSummaryItem title={`Overhead`} value={summary?.overhead} total={summary?.total} />
        <UtilizationSummaryItem title={`Tickets`} value={summary?.tickets} total={summary?.total} />
        <UtilizationSummaryItem title={`ChargeCodes`} value={summary?.chargeCodes} total={summary?.total} />
        {Object.entries(summary?.personal ?? {})
          .map(([key, value]) => <UtilizationSummaryItem key={`summary-personal-${key}`} title={summary?.personalNames[parseInt(`${key}`)] ?? `Unknown[${key}]`} value={value} total={summary?.total} />)}
      </div>
    </div>
  );
}