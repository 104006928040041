import {useAppSelectorWithParam} from "../../../../redux/hooks";
import {
  getTeamMemberDayTotalSecondsForLastActiveWorkTypeByTeamMemberId,
  getTeamMemberLastActivityEndTimeById
} from "../../../../redux/team-members/team-members.selectors";

export interface ITeamMemberPreviousActivityTimeLogic {
  timestamp?: string;
  dayPreviousTotalSec?: number;
}

export const useTeamMemberPreviousActivityTimeLogic = (teamMemberId: number): ITeamMemberPreviousActivityTimeLogic => {
  const timestamp = useAppSelectorWithParam(getTeamMemberLastActivityEndTimeById, teamMemberId);
  const dayPreviousTotalSec = useAppSelectorWithParam(getTeamMemberDayTotalSecondsForLastActiveWorkTypeByTeamMemberId, teamMemberId);
  //TODO: get user day running time in overhead
  return {
    timestamp,
    dayPreviousTotalSec,
  };
}
