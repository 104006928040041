import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IConnectedDetails {
  isConnected: true;
  connectedAt: number;
}
export interface IDisconnectedDetails {
  isConnected: false;
  disconnectedAt?: number;
}

export type ConnectionDetailsType = IConnectedDetails | IDisconnectedDetails;

export type ConnectionType = 'screen' | 'teamMembers' | 'serviceBoards';
export interface IConnectionStore extends Partial<Record<ConnectionType, ConnectionDetailsType>>{
  uuid?: string;
  token?: string;
  error?: string;
  isAuthenticated?: boolean;

}


const initialState: IConnectionStore = {

};

export interface IConnectedPayload {
  type: ConnectionType;
  connectedAt: number;
}
export interface IDisconnectedPayload {
  type: ConnectionType;
  disconnectedAt: number;
}

export interface IConnectionTokenPayload {
  uuid: string;
  token: string;
}


const connectionSlice = createSlice({
  name: `connection`,
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<IConnectionTokenPayload>) => {
      const {uuid, token} = action.payload;

      if (state.uuid === uuid && state.token === token) {
        // nothing changed
        return state;
      }

      return {
        ...state,
        uuid,
        token,
      };
    },
    setStatus: (state, action: PayloadAction<boolean>) => {
      const isAuthenticated = action.payload;

      return {
        ...state,
        isAuthenticated,
      };
    },
    setError: (state, action: PayloadAction<string>) => {
      const error = action.payload;
      return {
        ...state,
        isAuthenticated: false,
        error,
      };
    },
    reset: (state) => {
      return {
        ...initialState,
      };
    },
    setConnected: (state, action: PayloadAction<IConnectedPayload>) => {
      const {type, connectedAt} = action.payload;

      return {
        ...state,
        [type]: {
          isConnected: true,
          connectedAt,
        },
      };
    },
    setDisconnected: (state, action: PayloadAction<IDisconnectedPayload>) => {
      const {type, disconnectedAt} = action.payload;

      return {
        ...state,
        [type]: {
          isConnected: false,
          disconnectedAt,
        },
      };
    }
  },
});

export const {setToken, setStatus, setError, reset} = connectionSlice.actions;

export const connectionReducer = connectionSlice.reducer;
