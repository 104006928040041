import React from "react";
import type {FunctionComponent} from "react";
import s from "./ServiceBoardCard.module.scss";
import {Card, CardContent} from "@mui/material";
import CardHeader from '@mui/material/CardHeader';

export interface IServiceBoardCardProps {
  name: string;
  totalTicketCount: number;
  availableTicketCount: number;
  criticalAvailableCount: number;
  highAvailableCount: number;
  mediumAvailableCount: number;
  lowAvailableCount: number;
}

export const ServiceBoardCard: FunctionComponent<IServiceBoardCardProps> = ({name, totalTicketCount, availableTicketCount, criticalAvailableCount, highAvailableCount, mediumAvailableCount, lowAvailableCount}) => {
  return (
    <Card variant={`elevation`} className={s.container} raised={true} sx={{ m:undefined}}>
      <CardHeader title={name} disableTypography={true} className={s.header} sx={{borderBottom: 1}}/>
      <CardContent className={s.content} sx={{px: 0, pt: 0}}>
        <div className={s.availableOverTotalWrapper}>
          <span className={s.availableOverTotal}>
            {availableTicketCount}/{totalTicketCount}
          </span>

        </div>
        <div className={s.availablePriority}>
          {!!criticalAvailableCount && <div className={s.critical}>{criticalAvailableCount}</div>}
          {!!highAvailableCount && <div className={s.high}>{highAvailableCount}</div>}
          {!!mediumAvailableCount && <div className={s.medium}>{mediumAvailableCount}</div>}
          {!!lowAvailableCount && <div className={s.low}>{lowAvailableCount}</div>}
        </div>
      </CardContent>
    </Card>
  );
}

