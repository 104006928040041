import {useAppSelector} from "../../../redux/hooks";
import {
  doesConnectionHaveError,
  doesConnectionHaveToken,
  istConnectionAuthenticated,
} from "../../../redux/connection/connection.selectors";
import {useEffect} from "react";
import {connectionService} from "../../../services/connection/connection-service";


export interface IConnectionProviderLogic {
  hasToken: boolean;
  hasError: boolean;
  isAuthenticated: boolean;
}

export const useConnectionProviderLogic = (): IConnectionProviderLogic => {
  const hasToken = useAppSelector(doesConnectionHaveToken);
  const isAuthenticated = useAppSelector(istConnectionAuthenticated);
  const hasError = useAppSelector(doesConnectionHaveError);

  useEffect(() => {
    if (hasToken){
      // nothing to do
      return;
    }
    void connectionService.authenticate();
  }, [hasToken]);

  useEffect(() => {
    if (!isAuthenticated) {
      // nothing to do
      return;
    }
    void connectionService.setConnectionPresence();
  }, [isAuthenticated]);


  return {
    hasToken,
    isAuthenticated,
    hasError,
  };
}
