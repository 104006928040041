import React, {useEffect} from "react";
import type {VoidFunctionComponent} from "react";
import s from "./ServiceBoardsList.module.scss";
import {useServiceBoardsListLogic} from "./ServiceBoardsList.logic";
import {ServiceBoard} from "../searvice-board/ServiceBoard";
import {displayServiceBoardsEvent} from "../../services/log/events";

export interface IServiceBoardsListProps {
}

export const ServiceBoardsList: VoidFunctionComponent<IServiceBoardsListProps> = ({}) => {
  const {serviceBoardIds} = useServiceBoardsListLogic();

  useEffect(() => {
    displayServiceBoardsEvent(serviceBoardIds);
  }, [serviceBoardIds]);
  // return <div className={s.container}> ServiceBoardsList </div>;

  return (

    <div className={s.container}>
      {serviceBoardIds.map((serviceBoardId) => <ServiceBoard serviceBoardId={serviceBoardId} key={`service-board-${serviceBoardId}`} />)}
    </div>
  )
}

