import {getAnalytics, logEvent as firebaseLogEvent, setUserProperties as firebaseSetUserProperties,} from "firebase/analytics";
import {firebaseApp} from "./app";
import {appBuildVersionNumber} from "../../config/environment";

export const firebaseAnalytics = getAnalytics(firebaseApp);

export const logEvent = (eventName: string, eventParams?: Record<string, any>): void =>
  firebaseLogEvent(firebaseAnalytics, eventName, eventParams);

// log init view
logEvent('screen_view', {
  firebase_screen: `wallboard_screen`,
  firebase_screen_class: `web_wallboard_screen`,
  app_version: appBuildVersionNumber,
});

export const setUserProperties = (property: string, value: unknown): void => {
  firebaseSetUserProperties(firebaseAnalytics, {[property]: value});
}

export const setCurrentScreen = (screen?: string, screenClass?: string) =>
  firebaseLogEvent(firebaseAnalytics, "screen_view", {
    firebase_screen: screen ?? `wallboard_screen`,
    firebase_screen_class: screenClass ?? `web_wallboard_screen`,
    app_version: appBuildVersionNumber,
  });

// log initialize of app
setCurrentScreen(`initialize`, `web-initialize`);
