import React from "react";
import type {FunctionComponent} from "react";
import s from "./StatsBox.module.scss";

export interface IStatsBoxProps {
  className?: string[]|string;
  title: string;
  count: number;
}

export const StatsBox: FunctionComponent<IStatsBoxProps> = ({count, title, className}) => {
  return (
    <div className={s.container}>
      <div className={s.count}>{count}</div>
      <div className={s.title}>{title}</div>
    </div>
  );
}

