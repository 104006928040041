import {createSelector} from "@reduxjs/toolkit";
import {getScreenServiceBoardIds} from "../screen/screen.selectors";
import {
  getStatsClosedForServiceBoards,
  getStatsOpenedForServiceBoards,
  getStatsTotalForServiceBoards
} from "./service-boards.selectors";

export const getScreenServiceBoardTicketsOpenedToday = createSelector(
  [
    getScreenServiceBoardIds,
    getStatsOpenedForServiceBoards,
  ],
  (serviceBoardIds, statsOpenedForServiceBoards): number =>
    serviceBoardIds.map((serviceBoardId) => statsOpenedForServiceBoards[serviceBoardId] ?? 0)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
);
export const getScreenServiceBoardTicketsClosedToday = createSelector(
  [
    getScreenServiceBoardIds,
    getStatsClosedForServiceBoards,
  ],
  (serviceBoardIds, statsClosedForServiceBoards): number =>
    serviceBoardIds.map((serviceBoardId) => statsClosedForServiceBoards[serviceBoardId] ?? 0)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
);
export const getScreenServiceBoardTicketsTotalToday = createSelector(
  [
    getScreenServiceBoardIds,
    getStatsTotalForServiceBoards,
  ],
  (serviceBoardIds, statsTotalForServiceBoards): number =>
    serviceBoardIds.map((serviceBoardId) => statsTotalForServiceBoards[serviceBoardId] ?? 0)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
);
