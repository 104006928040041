import {useAppSelectorWithParam} from "../../../../redux/hooks";
import {getTeamMemberCurrentActivityStartTimeById} from "../../../../redux/team-members/team-members.selectors";
import {useEffect, useState} from "react";
import {toLocalTime,} from "../../../../factories/time";
import {isDefined} from "is-type-util";


export interface ITeamMemberCurrentActivityTimeLogic {
  startTime?: string;
}

export const useTeamMemberCurrentActivityTimeLogic = (teamMemberId: number): ITeamMemberCurrentActivityTimeLogic => {
  const startTimestamp = useAppSelectorWithParam(getTeamMemberCurrentActivityStartTimeById, teamMemberId);
  const [startTime, setStartTime] = useState<string|undefined>();
  useEffect(() => {
    if (isDefined(startTimestamp)) {
      setStartTime(toLocalTime(startTimestamp));
    } else {
      setStartTime(undefined);
    }
  }, [startTimestamp,])
  return {startTime};
}
