import {productMonitoring} from "./product-monitoring-service";
import {appBuildVersionDate, appBuildVersionNumber, appEnvVersion} from "../../config/environment";


export const initializedEvent = () =>
  productMonitoring.logEvent(`screen_initialized`, {
    app_build_date: appBuildVersionDate,
    app_build_version: appBuildVersionNumber,
    app_version: appEnvVersion,
  });

export const screenIdentifyEvent = (uuid: string) => {
  productMonitoring.identifyScreen(uuid);
}
export const authenticatedEvent = (isAuthenticated: boolean) =>
  productMonitoring.logEvent(`screen_authenticated`, {isAuthenticated});

export const displayTeamMembersEvent = (teamMemberIds: number[]) =>
  productMonitoring.logEvent(`view_item_list`, {items: teamMemberIds, item_list_name: `team-members`});

export const displayHasTeamMembersEvent = (hasTeamMembers: boolean) =>
  productMonitoring.logEvent(`has_team_members`, {hasTeamMembers});

export const displayServiceBoardsEvent = (serviceBoardsIds: number[]) =>
  productMonitoring.logEvent(`view_item_list`, {items: serviceBoardsIds, item_list_name: `service-boards`});
export const displayHasServiceBoardsEvent = (hasServiceBoards: boolean) =>
  productMonitoring.logEvent(`has_service_boards`, {hasServiceBoards});


export const displayScreenNeedsSetupEvent = () =>
  productMonitoring.logEvent(`screen_needs_setup`);

export const displayScreenThemeEvent = (theme?: string) =>
  productMonitoring.logEvent(`screen_theme`, {theme});
