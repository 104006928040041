import React from "react";
import type {VoidFunctionComponent} from "react";
import s from "./TeamMemberDayTimelineEntry.module.scss";
import {useTeamMemberDayTimelineEntryLogic} from "./TeamMemberDayTimelineEntry.logic";

export interface ITeamMemberDayTimelineEntryProps {
  id: number;
  type: "ChargeCode" | "ServiceTicket";
  duration: number;
}

export const TeamMemberDayTimelineEntry: VoidFunctionComponent<ITeamMemberDayTimelineEntryProps> = ({id, type, duration}) => {
  const {style} = useTeamMemberDayTimelineEntryLogic(id, type, duration);
  return <div className={s.container} style={{...style}} data-info={JSON.stringify({id, type, duration, style})} >  </div>;
}

