import {useAppSelector} from "../../redux/hooks";
import {getScreenType, isScreenSetup} from "../../redux/screen/screen.selectors";

export enum DisplayPageEnum {
  notSetup,
  app,
  mobile,
  wallboard,
}

export const useRouterPageLogic = (): DisplayPageEnum => {
  const isSetup = useAppSelector(isScreenSetup)
  const screenType = useAppSelector(getScreenType);
  if (!isSetup) {
    return DisplayPageEnum.notSetup;
  }

  switch (screenType ?? `wallboard`) {
    case "app":
      return DisplayPageEnum.app;
    case `mobile`:
      return DisplayPageEnum.mobile;
    case `wallboard`:
      return DisplayPageEnum.wallboard;
  }
  return DisplayPageEnum.wallboard;

}
