import {useAppSelector} from "../../../redux/hooks";
import {
  getScreenServiceBoardTicketsClosedToday,
  getScreenServiceBoardTicketsOpenedToday,
  getScreenServiceBoardTicketsTotalToday
} from "../../../redux/service-boards/service-boards-with-screen.selectors";

export interface IScreenStatsGroupLogic {
  total: number;
  opened: number;
  closed: number;
}

export const useScreenStatsGroupLogic = (): IScreenStatsGroupLogic => {
  const total = useAppSelector(getScreenServiceBoardTicketsTotalToday);
  const opened = useAppSelector(getScreenServiceBoardTicketsOpenedToday);
  const closed = useAppSelector(getScreenServiceBoardTicketsClosedToday);
  return {
    total,
    opened,
    closed,
  };
}
