import React from "react";
import type {FunctionComponent} from "react";
import s from "./ScreenStatsGroup.module.scss";
import {useScreenStatsGroupLogic} from "./ScreenStatsGroup.logic";
import {StatsBox} from "../../../components/screen-stats/box/StatsBox";

export interface IScreenStatsGroupProps {
}

export const ScreenStatsGroup: FunctionComponent<IScreenStatsGroupProps> = ({}) => {
  const {total, opened, closed} = useScreenStatsGroupLogic();
  return (
    <div className={s.container}>
      <StatsBox title={`Opened`} count={opened} />
      <StatsBox title={`Closed`} count={closed} />
      <StatsBox title={`Total`} count={total} />
    </div>
  );
}

