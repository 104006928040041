import {store} from "../../redux/store";
import {firebaseDatabaseService} from "../firebase/database";
import {clearServiceBoards, loadServiceBoards} from "../../redux/service-boards/service-boards.slice";
import {IServiceBoardData} from "../firebase/database-types";


class ServiceBoardsService {
  protected unsubscribeCallback?: () => void;

  protected handleUnsubscribe(): void {
    this.unsubscribeCallback?.();
    this.unsubscribeCallback = undefined;
  }

  public subScribe(){
    // make sure not already subscribed
    this.handleUnsubscribe();

    this.unsubscribeCallback = firebaseDatabaseService.subscribeToServiceBoards(this.handleChange.bind(this));
  }

  public unsubscribe(): void {
    this.handleUnsubscribe();
    store.dispatch(clearServiceBoards());
  }

  public handleChange(newValue?: Record<number, IServiceBoardData>): void | Promise<void> {
    store.dispatch(loadServiceBoards(Object.values(newValue ?? {})));
  }
}

export const serviceBoardService = new ServiceBoardsService();
