import {AbstractClient} from "./base-client";
import {AxiosResponse,} from "axios";

export class ScreensClient extends AbstractClient<number, {uuid: string}, never>{
  constructor() {
    super(`/api/screens`);
  }

  public async getList<RequestFilters = Record<string, unknown>, ResponseType = unknown>(filters?: RequestFilters): Promise<AxiosResponse<ResponseType>> {
    throw new Error(`Not Implemented`);
  }
  public async getOne<ResponseType = unknown>(identifier: number): Promise<AxiosResponse<ResponseType>> {
    throw new Error(`Not Implemented`);
  }

  async updateOne<ResponseType = unknown>(identifier: number, data: never): Promise<AxiosResponse<ResponseType>> {
    throw new Error(`Not Implemented`);
  }
}

export const screensClient = new ScreensClient();
