import {ITeamMemberDayActivityItem} from "../../../../services/firebase/database-types";
import {useAppSelectorWithParam} from "../../../../redux/hooks";
import {getDailyActivityTimelineByTeamMemberId} from "../../../../redux/team-members/team-members.selectors";

export interface ITeamMemberDayTimelineLogic {
  shouldDisplay: boolean;
  timeline: ITeamMemberDayActivityItem[]
}

export const useTeamMemberDayTimelineLogic = (teamMemberId: number): ITeamMemberDayTimelineLogic => {
  const timeline: ITeamMemberDayActivityItem[] = useAppSelectorWithParam(getDailyActivityTimelineByTeamMemberId, teamMemberId);
  const shouldDisplay = (timeline.length > 0);
  return {
    shouldDisplay,
    timeline,
  };
}
