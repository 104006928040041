import type {AppState} from "../store";
import type {IServiceBoardsStore} from "./service-boards.slice";
import {
  IServiceBoardAvailableTicketsData,
  IServiceBoardData,
  IServiceBoardStatsData
} from "../../services/firebase/database-types";
import {createSelector} from "@reduxjs/toolkit";

const getServiceBoardsStore = (store: AppState): IServiceBoardsStore =>
  store.serviceBoards;

const getServiceBoardsIds = (store: AppState): number[] =>
  getServiceBoardsStore(store).ids ?? [];
const getServiceBoardStoreData = (store: AppState): Record<number, IServiceBoardData> =>
  getServiceBoardsStore(store).byId ?? {};

export const getServiceBoardStoreById = (store: AppState, id: number): IServiceBoardData | undefined =>
  getServiceBoardStoreData(store)[id] ?? undefined;
export const getServiceBoardNameById = (store: AppState, id: number): string =>
  `${getServiceBoardStoreById(store, id)?.name ?? `unknown`}`;

export const getStatsByServiceBoardId = (store: AppState, id: number): IServiceBoardStatsData | undefined =>
  getServiceBoardStoreById(store, id)?.stats;

export const getStatsForServiceBoards = createSelector(
  [
    getServiceBoardsIds,
    getServiceBoardStoreData,
  ],
  (serviceBoardIds, serviceBoardsData): Record<number, IServiceBoardStatsData> => {
    const serviceBoardStats: Record<number, IServiceBoardStatsData> = {};
    serviceBoardIds.forEach((serviceBoardId: number) => {
      serviceBoardStats[serviceBoardId] = {
        ...serviceBoardsData[serviceBoardId].stats,
      };
    });
    return serviceBoardStats;
  }
);

export const getStatsOpenedForServiceBoards = createSelector(
  [
    getServiceBoardsIds,
    getStatsForServiceBoards,
  ],
  (serviceBoardIds, statsForServiceBoards): Record<number, number> => {
    const statsOpenedForServiceBoards: Record<number, number> = {};
    serviceBoardIds.forEach((serviceBoardId: number) => {
      statsOpenedForServiceBoards[serviceBoardId] = statsForServiceBoards[serviceBoardId].opened;
    });
    return statsOpenedForServiceBoards;
  }
)
export const getStatsClosedForServiceBoards = createSelector(
  [
    getServiceBoardsIds,
    getStatsForServiceBoards,
  ],
  (serviceBoardIds, statsForServiceBoards): Record<number, number> => {
    const statsClosedForServiceBoards: Record<number, number> = {};
    serviceBoardIds.forEach((serviceBoardId: number) => {
      statsClosedForServiceBoards[serviceBoardId] = statsForServiceBoards[serviceBoardId].closed;
    });
    return statsClosedForServiceBoards;
  }
)
export const getStatsTotalForServiceBoards = createSelector(
  [
    getServiceBoardsIds,
    getStatsForServiceBoards,
  ],
  (serviceBoardIds, statsForServiceBoards): Record<number, number> => {
    const statsTotalForServiceBoards: Record<number, number> = {};
    serviceBoardIds.forEach((serviceBoardId: number) => {
      statsTotalForServiceBoards[serviceBoardId] = statsForServiceBoards[serviceBoardId].total;
    });
    return statsTotalForServiceBoards;
  }
)
export const getTotalTicketStatsByServiceBoardId = (store: AppState, id: number): number | undefined =>
  getStatsByServiceBoardId(store, id)?.total;



export const getServiceBoardAvailableTicketsById = (store: AppState, id: number): Partial<IServiceBoardAvailableTicketsData> =>
  getServiceBoardStoreById(store, id)?.availableTickets ?? {};

export const getServiceBoardCriticalTicketsById = (store: AppState, id: number): number =>
  getServiceBoardAvailableTicketsById(store, id).critical ?? 0;
export const getServiceBoardHighTicketsById = (store: AppState, id: number): number =>
  getServiceBoardAvailableTicketsById(store, id).high ?? 0;
export const getServiceBoardMediumTicketsById = (store: AppState, id: number): number =>
  getServiceBoardAvailableTicketsById(store, id).medium ?? 0;
export const getServiceBoardLowTicketsById = (store: AppState, id: number): number =>
  getServiceBoardAvailableTicketsById(store, id).low ?? 0;

export const getTotalUnAssignedTicketsByServiceBoardId = createSelector(
  [
    getServiceBoardCriticalTicketsById,
    getServiceBoardHighTicketsById,
    getServiceBoardMediumTicketsById,
    getServiceBoardLowTicketsById
  ],
  (critical: number, high: number, medium: number, low: number): number =>
    critical + high + medium + low
);
