import {useAppSelectorWithParam} from "../../redux/hooks";
import {doesTeamMemberHaveAnyActivityToday} from "../../redux/team-members/team-members.selectors";

export interface ITeamMemberLogic {
  hasAnyActivityToday: boolean;
}

export const useTeamMemberLogic = (teamMemberId: number): ITeamMemberLogic => {
  const hasAnyActivityToday = useAppSelectorWithParam<boolean, number>(doesTeamMemberHaveAnyActivityToday, teamMemberId);
  return {hasAnyActivityToday};
}
