import React from "react";
import type {VoidFunctionComponent} from "react";
import s from "./TeamMemberDayTimeline.module.scss";
import {useTeamMemberDayTimelineLogic} from "./TeamMemberDayTimeline.logic";
import {TeamMemberDayTimelineEntry} from "../day-timeline-entry/TeamMemberDayTimelineEntry";

export interface ITeamMemberDayTimelineProps {
  teamMemberId: number;
}

export const TeamMemberDayTimeline: VoidFunctionComponent<ITeamMemberDayTimelineProps> = ({teamMemberId}) => {
  const {shouldDisplay, timeline} = useTeamMemberDayTimelineLogic(teamMemberId);
  if (!shouldDisplay) {
    return null;
  }

  return <div className={s.container}> {timeline.map((timeEntry) => <TeamMemberDayTimelineEntry {...timeEntry} key={`timeline-entry-${timeEntry.start}-${timeEntry.duration}-${timeEntry.id}`} />)} </div>;
}

