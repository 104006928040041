import {isUndefined} from "is-type-util";

export const stringToInt = (input: string): number =>
  parseInt(input, 10);

export const calculatePercentage = (partialValue?: number, totalValue?: number): number | undefined => {
  if (isUndefined(partialValue) || isUndefined(totalValue) || totalValue === 0) {
    // missing something needed to calculate percentage
    return undefined;
  }
  if (partialValue === 0) {
    // zero
    return 0;
  }

  return (100 * partialValue) / totalValue;
}
