import {getAuth, signInWithCustomToken} from "firebase/auth";
import type {Auth as FirebaseAuth} from "firebase/auth";
import {firebaseApp} from "./app";

export type FirebaseAuthClient = FirebaseAuth;
export const firebaseAuth = getAuth(firebaseApp) as FirebaseAuthClient;


export const signInWithToken = (token: string): Promise<boolean> =>
  signInWithCustomToken(firebaseAuth, token)
    .then((userCredential) => {
      return true;
    })
    .catch((error) => {
      console.error(`signInWithToken`, {error});
      return false;
    })
