import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ITeamMemberData} from "../../services/firebase/database-types";






export interface ITeamMembersStore {
  byId?: Record<number, ITeamMemberData>;
}

const initialState: ITeamMembersStore = {

};


const teamMembersSlice = createSlice({
  name: `teamMembers`,
  initialState,
  reducers: {
    load: (state, action: PayloadAction<ITeamMemberData[] | undefined>) => {

      const newState: ITeamMembersStore = {
        ...state,
        byId: {},
      };

      const teamMembers = action.payload ?? [];
      teamMembers.forEach((teamMember) => {
        newState.byId![teamMember.id] = {...teamMember};
      });

      return newState;
    },

    clear: (state) => {
      return {
        ...initialState,
      }
    }
  }
});

export const teamMembersReducer = teamMembersSlice.reducer;
export const {load: loadTeamMembers, clear: clearTeamMembers} = teamMembersSlice.actions;
