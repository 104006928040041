import {useAppSelectorWithParams} from "../../../../redux/hooks";
import {
  getDisplayStyleByTypeAndId
} from "../../../../redux/display-config/display-config.selectors";
import {DisplayConfigStyle} from "../../../../services/firebase/database-types";

export interface ITeamMemberDayTimelineEntryLogic {
  style?: DisplayConfigStyle & {flexBasis: string;};
}

export const useTeamMemberDayTimelineEntryLogic = (id: number, type: "ChargeCode" | "ServiceTicket", duration: number,): ITeamMemberDayTimelineEntryLogic => {
  const style = useAppSelectorWithParams(getDisplayStyleByTypeAndId, type, id)
  return {
    style: {
      ...style,
      flexBasis: `${duration}px`,
    }
  };
}
