import React, {PropsWithChildren} from "react";
import type {FunctionComponent} from "react";
import {useScreenConnectionProviderLogic} from "./ScreenConnectionProvider.logic";

export interface IScreenConnectionProviderProps {
}

export const ScreenConnectionProvider: FunctionComponent<PropsWithChildren<IScreenConnectionProviderProps>> = ({children}) => {
  useScreenConnectionProviderLogic()
  return <>{children}</>;
}

