import {firebaseDatabaseService} from "../firebase/database";
import {store} from "../../redux/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {clearServiceBoards, loadServiceBoards} from "../../redux/service-boards/service-boards.slice";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {IDisplayConfigData, IServiceBoardData} from "../firebase/database-types";
import {clearDisplayConfigData, setDisplayConfigData} from "../../redux/display-config/display-config.slice";


class DisplayConfigService {
  protected unsubscribeCallback?: () => void;

  protected handleUnsubscribe(): void {
    this.unsubscribeCallback?.();
    this.unsubscribeCallback = undefined;
  }

  public subScribe(){
    // make sure not already subscribed
    this.handleUnsubscribe();

    this.unsubscribeCallback = firebaseDatabaseService.subscribeToDisplayConfig(this.handleChange.bind(this));
  }

  public unsubscribe(): void {
    this.handleUnsubscribe();
    store.dispatch(clearDisplayConfigData());
  }

  public handleChange(newValue?: Partial<IDisplayConfigData>): void | Promise<void> {
    store.dispatch(setDisplayConfigData(newValue ?? {}));
  }
}

export const displayConfigService = new DisplayConfigService();
