import React from "react";
import type {VoidFunctionComponent} from "react";
import {useServiceBoardLogic} from "./ServiceBoard.logic";
import {ServiceBoardCard} from "../../components/service-board-stats/card/ServiceBoardCard";

export interface IServiceBoardProps {
  serviceBoardId: number;
}

export const ServiceBoard: VoidFunctionComponent<IServiceBoardProps> = ({serviceBoardId}) => {
  const {
    name,
    totalTicketCount,
    availableTicketCount,
    criticalAvailableCount,
    highAvailableCount,
    mediumAvailableCount,
    lowAvailableCount,
  } = useServiceBoardLogic(serviceBoardId);

  return (
    <ServiceBoardCard
      name={name}
      totalTicketCount={totalTicketCount}
      availableTicketCount={availableTicketCount}
      criticalAvailableCount={criticalAvailableCount}
      highAvailableCount={highAvailableCount}
      mediumAvailableCount={mediumAvailableCount}
      lowAvailableCount={lowAvailableCount}
    />
  );
  //
  // return (
  //   <div className={s.container}>
  //     <ServiceBoardTitle serviceBoardId={serviceBoardId} />
  //     <div className={s.availableOverTotal}>{available}/{total}</div>
  //     <ServiceBoardAvailableTickets serviceBoardId={serviceBoardId} />
  //   </div>
  // );
}

