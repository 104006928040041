import axios, {AxiosInstance, AxiosResponse} from "axios";
import {systemConfig,} from "../../config/system";
import {lastCharacterOfString} from "../../util/string-util";
import {injectQueryDataInUrl, ParsedUrlQueryInputType} from "../../factories/request-url-factory";
import {isUndefined} from "is-type-util";

const axiosInstance = axios.create({
  baseURL: systemConfig.baseUrl,
  // timeout: 1000,
  headers: {
  },
});

export abstract class AbstractClient <
  ItemIdentifierType extends string|number = string,
  CreateRequestDataType = unknown,
  UpdateRequestDataType = unknown,
> {
  protected shouldCreateViaFormPost: boolean = false;
  protected shouldUpdateViaFormPut: boolean = false;

  protected constructor(
    private readonly rootPathConfig?: string | FunctionWithReturn<string>,
  ) {
  }

  protected get instance(): AxiosInstance {
    return axiosInstance;
  }

  protected get rootPath(): string {
    if (isUndefined(this.rootPathConfig)) {
      throw new Error('root path must be configured');
    }

    let path = this.rootPathConfig!;

    if (typeof path === "function") {
      path = path();
    }

    if (lastCharacterOfString(path) === '/') {
      throw new Error('root path must not end in /');
    }

    return path;
  }

  public async getList<RequestFilters = Record<string, unknown>, ResponseType = unknown>(filters?: RequestFilters): Promise<AxiosResponse<ResponseType>> {
    const requestUrl = injectQueryDataInUrl(this.rootPath!, filters as ParsedUrlQueryInputType);
    return await this.instance
      .get<ResponseType>(requestUrl);
  }

  public async getOne<ResponseType = unknown>(identifier: ItemIdentifierType): Promise<AxiosResponse<ResponseType>> {
    const requestUrl = `${this.rootPath}/${identifier}`;
    return await this.instance
      .get<ResponseType>(requestUrl);
  }

  public async deleteOne<ResponseType = unknown>(identifier: ItemIdentifierType): Promise<AxiosResponse<ResponseType>> {
    const requestUrl = `${this.rootPath}/${identifier}`;
    return await this.instance
      .delete<ResponseType>(requestUrl);
  }

  public async createOne<ResponseType = unknown>(data: CreateRequestDataType): Promise<AxiosResponse<ResponseType>> {
    if (this.shouldCreateViaFormPost) {
      return await this.instance
        .postForm<ResponseType, AxiosResponse<ResponseType>, CreateRequestDataType>(this.rootPath, data);
    }
    return await this.instance
      .post<ResponseType, AxiosResponse<ResponseType>, CreateRequestDataType>(this.rootPath, data);
  }

  public async updateOne<ResponseType = unknown>(identifier: ItemIdentifierType, data: UpdateRequestDataType): Promise<AxiosResponse<ResponseType>> {
    const requestUrl = `${this.rootPath}/${identifier}`;

    if (this.shouldUpdateViaFormPut) {
      return await this.instance
        .putForm<ResponseType, AxiosResponse<ResponseType>, UpdateRequestDataType>(requestUrl, data);
    }
    return await this.instance
      .put<ResponseType, AxiosResponse<ResponseType>, UpdateRequestDataType>(requestUrl, data);
  }
}
