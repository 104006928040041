import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;


export function useAppSelectorWithParam<
  ExpectedReturnType = unknown,
  Param1Type = never,
>(selector: (state: AppState, p1: Param1Type) => ExpectedReturnType, p1: Param1Type): ExpectedReturnType {
  return useAppSelector<ExpectedReturnType>((state) => selector(state, p1));
}

export function useAppSelectorWithParams<
  ExpectedReturnType = unknown,
  Param1Type = never,
  Param2Type = never,
>(selector: (state: AppState, p1?: Param1Type, p2?: Param2Type) => ExpectedReturnType, p1?: Param1Type, p2?: Param2Type): ExpectedReturnType {
  return useAppSelector<ExpectedReturnType>((state) => selector(state, p1, p2));
}

