import React from "react";
import type {VoidFunctionComponent} from "react";
import s from "./TeamMember.module.scss";
import {TeamMemberName} from "./modules/name/TeamMemberName";
import {TeamMemberActivity} from "./modules/activity/TeamMemberActivity";
import {useTeamMemberLogic} from "./TeamMember.logic";
import {withClassNames} from "../../factories/with-class-names";
import {TeamMemberAssignedTotal} from "./modules/assigned-total/TeamMemberAssignedTotal";
import {TeamMemberDayTimeline} from "./modules/day-timeline/TeamMemberDayTimeline";

export interface ITeamMemberProps {
  teamMemberId: number;
}

export const TeamMember: VoidFunctionComponent<ITeamMemberProps> = ({teamMemberId}) => {
  const {hasAnyActivityToday} = useTeamMemberLogic(teamMemberId);
  return (
    <div className={withClassNames(s.container, {condition: hasAnyActivityToday, classNames: s.hasAnyActivityToday})}>
      <div className={s.wrapper}>
        <div className={s.top} >
          <div className={s.userId}>{teamMemberId}</div>
          <TeamMemberName teamMemberId={teamMemberId} />
          <TeamMemberAssignedTotal teamMemberId={teamMemberId} />
        </div>
        <TeamMemberDayTimeline teamMemberId={teamMemberId} />
        <TeamMemberActivity teamMemberId={teamMemberId} />

      </div>
    </div>
  );
}

