import React from "react";
import type {VoidFunctionComponent} from "react";
import s from "./TeamMemberName.module.scss";
import {useTeamMemberNameLogic} from "./TeamMemberName.logic";

export interface ITeamMemberNameProps {
  teamMemberId: number;
}

export const TeamMemberName: VoidFunctionComponent<ITeamMemberNameProps> = ({teamMemberId}) => {
  const name = useTeamMemberNameLogic(teamMemberId);
  return <div className={s.name} >{name}</div>;
}

