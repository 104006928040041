import React from 'react';
import './App.css';
import {RouterPage} from "./pages/router/RouterPage";
import {ConnectionProvider} from "./modules/connection/connection-provider/ConnectionProvider";
import {ScreenConnectionProvider} from "./modules/connection/screen-connection-provider/ScreenConnectionProvider";

function App() {
  return (
    <div>
      <ConnectionProvider>
        <ScreenConnectionProvider>
          <RouterPage />
        </ScreenConnectionProvider>
      </ConnectionProvider>
    </div>
  );
}

export default App;
