import {firebaseDatabaseClient, FirebaseDatabaseClient} from "../../clients/firebase/database";

import {IDisplayConfigData, IScreenData, IServiceBoardData, ITeamMemberData, ScreenUuidType} from "./database-types";

class FirebaseDatabaseService {

  private get client(): FirebaseDatabaseClient {
    return firebaseDatabaseClient;
  }

  public subscribeToScreen(screenUuid: ScreenUuidType, callback: (data: IScreenData) => void | Promise<void>): () => void {
    const unsubscribe = this.client.subscribe(`/screens/${screenUuid}`, callback);
    return unsubscribe;
  }
  public subscribeToServiceBoards(callback: (newValue?: Record<number, IServiceBoardData>) => void | Promise<void>): () => void {
    const unsubscribe = this.client.subscribe(`/serviceBoards`, callback);
    return unsubscribe;
  }

  public subscribeToTeamMembers(callback: (newValue?: Record<number, ITeamMemberData>|ITeamMemberData[]) => void | Promise<void>): () => void {
    const unsubscribe = this.client.subscribe(`/teamMembers`, callback);
    return unsubscribe;
  }
  public subscribeToDisplayConfig(callback: (newValue?: Partial<IDisplayConfigData>) => void | Promise<void>): () => void {
    const unsubscribe = this.client.subscribe(`/displayConfig`, callback);
    return unsubscribe;
  }

}

export const firebaseDatabaseService = new FirebaseDatabaseService();
