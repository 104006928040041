

export const lastCharacterOfString = (input?: string): string|undefined =>
    input?.slice(-1);


export const toLowercaseTrim = (inputString?: string) =>
    (inputString ?? ``).trim().toLowerCase();

export const capitalizeFirstLetter = (inputString: string) =>
    `${inputString.charAt(0).toUpperCase()}${inputString.slice(1)}`;
