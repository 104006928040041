import type {VoidFunctionComponent} from "react";
import React from "react";
import s from "./TeamMemberActivity.module.scss";
import {TeamMemberActivityTimeDisplayEnum, useTeamMemberActivityLogic} from "./TeamMemberActivity.logic";
import {TeamMemberCurrentActivityTime} from "../current-activity-time/TeamMemberCurrentActivityTime";
import {TeamMemberPreviousActivityTime} from "../previous-activity-time/TeamMemberPreviousActivityTime";

export interface ITeamMemberActivityProps {
  teamMemberId: number;
}

export const TeamMemberActivity: VoidFunctionComponent<ITeamMemberActivityProps> = ({teamMemberId}) => {
  const {displayTimeType, type, style, title, id} = useTeamMemberActivityLogic(teamMemberId);

  if (displayTimeType === TeamMemberActivityTimeDisplayEnum.notClockedIn) {
    // not clocked in
    return (
      <div className={[s.activity, s.inactive].join(' ')}>
        Not Clocked In
      </div>
    );
  }

  return (
    <div className={s.activity} style={{...style}}>
      <div className={s.top}>
        <div className={s.type}>{type}</div>
        {(!!id) && <div className={s.id}>{id}</div>}
      </div>
      <div className={s.title}>{title}</div>
      <div className={s.time}>
        {(displayTimeType === TeamMemberActivityTimeDisplayEnum.current) && <TeamMemberCurrentActivityTime teamMemberId={teamMemberId} />}
        {(displayTimeType === TeamMemberActivityTimeDisplayEnum.previous) && <TeamMemberPreviousActivityTime teamMemberId={teamMemberId} />}
      </div>
    </div>
  );
}

