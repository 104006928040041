import React from "react";
import type {FunctionComponent} from "react";
import {DisplayPageEnum, useRouterPageLogic} from "./RouterPage.logic";
import {NeedSetupPage} from "../need-setup/NeedSetupPage";
import WallboardPage from "../wallboard/WallboardPage";



export const RouterPage: FunctionComponent = ({}) => {
  const displayPage = useRouterPageLogic();

  switch (displayPage) {
    case DisplayPageEnum.notSetup:
      return <NeedSetupPage />;
    case DisplayPageEnum.wallboard:
      return <WallboardPage />;
  }
  return <div > {displayPage} </div>;
}

