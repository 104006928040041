import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IServiceBoardData} from "../../services/firebase/database-types";


export interface IServiceBoardAvailableTickets {

  total?: number;
  critical?: number;
  high?: number;
  medium?: number;
  low?: number;
}
export interface IServiceBoard {
  id: number;
  name: string;
  isEnabled: boolean;
  availableTickets?: IServiceBoardAvailableTickets;
}

export interface IServiceBoardsStore {
  ids?: number[];
  byId?: Record<number, IServiceBoardData>;
}

const initialState: IServiceBoardsStore = {

};

const serviceBoardsSlice = createSlice({
  name: `serviceBoards`,
  initialState,
  reducers: {
    load: (state, action: PayloadAction<IServiceBoardData[] | undefined>) => {
      const newState: IServiceBoardsStore = {
        ...state,
        ids: [],
        byId: {},
      };
      const serviceBoards = action.payload ?? [];
      serviceBoards.forEach((serviceBoard) => {
        newState.ids?.push(serviceBoard.id);
        newState.byId![serviceBoard.id] = {...serviceBoard};
      });

      // make unique
      newState.ids = Array.from(new Set(newState.ids))
        .sort();

      return newState;
    },

    clear: (state) => {
      return {
        ...initialState,
      }
    }
  }
});

export const serviceBoardsReducer = serviceBoardsSlice.reducer;

export const {load: loadServiceBoards, clear: clearServiceBoards} = serviceBoardsSlice.actions;

