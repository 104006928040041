import React, {PropsWithChildren, useEffect} from "react";
import type {FunctionComponent} from "react";
import {useConnectionProviderLogic} from "./ConnectionProvider.logic";
import {appEnvVersion} from "../../../config/environment";
import {setCurrentScreen} from "../../../clients/firebase/analytics";

export interface IConnectionProviderProps {
}

export const ConnectionProvider: FunctionComponent<PropsWithChildren<IConnectionProviderProps>> = ({children}) => {
  const {hasToken, isAuthenticated, hasError} = useConnectionProviderLogic();

  useEffect(() => {
    if (hasError) {
      setCurrentScreen(`connectionError`, `ConnectionProvider`);
      return;
    }
    if (!hasToken) {
      setCurrentScreen(`createAuthentication`, `ConnectionProvider`);
      return;
    }

    if (!isAuthenticated) {
      setCurrentScreen(`authenticating`, `ConnectionProvider`);
      return;
    }
    setCurrentScreen(`authenticated`, `ConnectionProvider`);
  }, [hasToken, isAuthenticated, hasError]);
  if (!hasToken) {
    return (
      <div>
        <div>Connection: Creating Authentication</div>
        <div>Version: {appEnvVersion}</div>
      </div>
    );
  }

  if (hasError) {
    return (
    <div>
      <div>Error Connecting</div>
      <div>Version: {appEnvVersion}</div>
    </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div>
        <div>Connection: Authenticating</div>
        <div>Version: {appEnvVersion}</div>
      </div>
    );
  }
  return <>{children}</>;
}

