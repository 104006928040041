import {firebaseDatabaseClient} from "./database";

export class FirebaseDatabaseUpdateClient {

  private changes: Record<string, unknown> = {};


  constructor( private basePath: string) {
  }

  public get hasChanges(): boolean
  {
    const pendingChangeCount: number = Object.keys(this.changes).length;
    return pendingChangeCount > 0;
  }

  public addChange<ChangeValueType = unknown>(subPath: string, value: ChangeValueType): this
  {
    this.changes[subPath] = value;
    return this;
  }

  public async save(): Promise<void>
  {
    if (!this.hasChanges) {
      // no changes, noting to do
      // return to exit
      return;
    }

    await firebaseDatabaseClient.update(this.basePath, this.changes);
    this.changes = {};
  }


}
